/*
* Common
*/
.loader-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.loader-wrapper .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
}
.loader {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  font-size: 40px;
  text-indent: -9999em;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.loader.loader-default {
  width: 1em;
  height: 1em;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: loader-default 1s infinite ease-in-out;
  -o-animation: loader-default 1s infinite ease-in-out;
  animation: loader-default 1s infinite ease-in-out;
}
.loader.loader-grill {
  width: .25em;
  height: .5em;
  background: #fff;
  -webkit-animation: default-grill 1s infinite ease-in-out;
  -o-animation: default-grill 1s infinite ease-in-out;
  animation: default-grill 1s infinite ease-in-out;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader.loader-grill:before,
.loader.loader-grill:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #fff;
  -webkit-animation: default-grill 1s infinite ease-in-out;
  -o-animation: default-grill 1s infinite ease-in-out;
  animation: default-grill 1s infinite ease-in-out;
}
.loader.loader-grill:before {
  left: -0.375em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader.loader-grill:after {
  left: .375em;
}
.loader.loader-circle {
  width: 1em;
  height: 1em;
  border-top: 0.125em solid rgba(255, 255, 255, 0.5);
  border-right: 0.125em solid rgba(255, 255, 255, 0.5);
  border-bottom: 0.125em solid rgba(255, 255, 255, 0.5);
  border-left: 0.125em solid #fff;
  border-radius: 50%;
  -webkit-animation: loader-circle 1.1s infinite linear;
  -o-animation: loader-circle 1.1s infinite linear;
  animation: loader-circle 1.1s infinite linear;
}
.loader.loader-round-circle {
  width: 1em;
  height: 1em;
  font-size: 10px;
  border-radius: 50%;
  -webkit-animation: loader-round-circle 1.3s infinite linear;
  -o-animation: loader-round-circle 1.3s infinite linear;
  animation: loader-round-circle 1.3s infinite linear;
}
.loader.loader-tadpole {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  -webkit-animation: loader-tadpole 1.7s infinite ease;
  -o-animation: loader-tadpole 1.7s infinite ease;
  animation: loader-tadpole 1.7s infinite ease;
}
.loader.loader-ellipsis {
  width: .625em;
  height: .625em;
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out;
  animation: loader-ellipsis 1.8s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader.loader-ellipsis:before,
.loader.loader-ellipsis:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out;
  animation: loader-ellipsis 1.8s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.loader.loader-ellipsis:before {
  left: -0.875em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader.loader-ellipsis:after {
  left: .875em;
}
.loader.loader-dot {
  width: 2em;
  height: 2em;
  -webkit-animation: loader-dot-rotate 2s infinite linear;
  -o-animation: loader-dot-rotate 2s infinite linear;
  animation: loader-dot-rotate 2s infinite linear;
}
.loader.loader-dot:before,
.loader.loader-dot:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 60%;
  content: '';
  background: #fff;
  border-radius: 100%;
  -webkit-animation: loader-dot-bounce 2s infinite ease-in-out;
  -o-animation: loader-dot-bounce 2s infinite ease-in-out;
  animation: loader-dot-bounce 2s infinite ease-in-out;
}
.loader.loader-dot:after {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader.loader-bounce {
  width: 1.5em;
  height: 1.5em;
}
.loader.loader-bounce:before,
.loader.loader-bounce:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #fff;
  border-radius: 50%;
  opacity: .6;
  -webkit-animation: loader-bounce 2s infinite ease-in-out;
  -o-animation: loader-bounce 2s infinite ease-in-out;
  animation: loader-bounce 2s infinite ease-in-out;
}
.loader.loader-bounce:after {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.side-panel-loading,
body > .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20px;
}
.site-menubar-unfold > .loader {
  margin-left: 130px;
}
.site-menubar-fold > .loader {
  margin-left: 45px;
}
.site-menubar-hide.site-menubar-unfold > .loader {
  margin-left: 0;
}
@keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes default-grill {
  0%,
  80%,
  100% {
    height: 1em;
    box-shadow: 0 0 #fff;
  }
  40% {
    height: 1.2em;
    box-shadow: 0 -0.25em #fff;
  }
}
@keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-round-circle {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em #fff, 2em -2em 0 0 #fff, 3em 0 0 -0.5em #fff, 2em 2em 0 -0.5em #fff, 0 3em 0 -0.5em #fff, -2em 2em 0 -0.5em #fff, -3em 0 0 -0.5em #fff, -2em -2em 0 0 #fff;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #fff, 2em -2em 0 0.2em #fff, 3em 0 0 0 #fff, 2em 2em 0 -0.5em #fff, 0 3em 0 -0.5em #fff, -2em 2em 0 -0.5em #fff, -3em 0 0 -0.5em #fff, -2em -2em 0 -0.5em #fff;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em #fff, 2em -2em 0 0 #fff, 3em 0 0 0.2em #fff, 2em 2em 0 0 #fff, 0 3em 0 -0.5em #fff, -2em 2em 0 -0.5em #fff, -3em 0 0 -0.5em #fff, -2em -2em 0 -0.5em #fff;
  }
  37.5% {
    box-shadow: 0 -3em 0 -0.5em #fff, 2em -2em 0 -0.5em #fff, 3em 0 0 0 #fff, 2em 2em 0 0.2em #fff, 0 3em 0 0 #fff, -2em 2em 0 -0.5em #fff, -3em 0 0 -0.5em #fff, -2em -2em 0 -0.5em #fff;
  }
  50% {
    box-shadow: 0 -3em 0 -0.5em #fff, 2em -2em 0 -0.5em #fff, 3em 0 0 -0.5em #fff, 2em 2em 0 0 #fff, 0 3em 0 0.2em #fff, -2em 2em 0 0 #fff, -3em 0 0 -0.5em #fff, -2em -2em 0 -0.5em #fff;
  }
  62.5% {
    box-shadow: 0 -3em 0 -0.5em #fff, 2em -2em 0 -0.5em #fff, 3em 0 0 -0.5em #fff, 2em 2em 0 -0.5em #fff, 0 3em 0 0 #fff, -2em 2em 0 0.2em #fff, -3em 0 0 0 #fff, -2em -2em 0 -0.5em #fff;
  }
  75% {
    box-shadow: 0 -3em 0 -0.5em #fff, 2em -2em 0 -0.5em #fff, 3em 0 0 -0.5em #fff, 2em 2em 0 -0.5em #fff, 0 3em 0 -0.5em #fff, -2em 2em 0 0 #fff, -3em 0 0 0.2em #fff, -2em -2em 0 0 #fff;
  }
  87.5% {
    box-shadow: 0 -3em 0 0 #fff, 2em -2em 0 -0.5em #fff, 3em 0 0 -0.5em #fff, 2em 2em 0 -0.5em #fff, 0 3em 0 -0.5em #fff, -2em 2em 0 0 #fff, -3em 0 0 0 #fff, -2em -2em 0 0.2em #fff;
  }
}
@keyframes loader-tadpole {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em #fff, 0 -0.83em 0 -0.42em #fff, 0 -0.83em 0 -0.44em #fff, 0 -0.83em 0 -0.46em #fff, 0 -0.83em 0 -0.477em #fff;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em #fff, 0 -0.83em 0 -0.42em #fff, 0 -0.83em 0 -0.44em #fff, 0 -0.83em 0 -0.46em #fff, 0 -0.83em 0 -0.477em #fff;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em #fff, -0.087em -0.825em 0 -0.42em #fff, -0.173em -0.812em 0 -0.44em #fff, -0.256em -0.789em 0 -0.46em #fff, -0.297em -0.775em 0 -0.477em #fff;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em #fff, -0.338em -0.758em 0 -0.42em #fff, -0.555em -0.617em 0 -0.44em #fff, -0.671em -0.488em 0 -0.46em #fff, -0.749em -0.34em 0 -0.477em #fff;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em #fff, -0.377em -0.74em 0 -0.42em #fff, -0.645em -0.522em 0 -0.44em #fff, -0.775em -0.297em 0 -0.46em #fff, -0.82em -0.09em 0 -0.477em #fff;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em #fff, 0 -0.83em 0 -0.42em #fff, 0 -0.83em 0 -0.44em #fff, 0 -0.83em 0 -0.46em #fff, 0 -0.83em 0 -0.477em #fff;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-ellipsis {
  0%,
  80%,
  100% {
    box-shadow: 0 0.625em 0 -0.325em #fff;
  }
  40% {
    box-shadow: 0 0.625em 0 0 #fff;
  }
}
@keyframes loader-dot-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-dot-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes loader-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.raport-property-list-item {
  display: block;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
.raport-property-list-item a {
  transition: color .15s linear;
}
.raport-property-list-item .raport-property-list-image {
  width: 15%;
  float: left;
  position: relative;
  background-color: rgba(0, 0, 0, 0.15);
}
.raport-property-list-item .raport-property-list-image:hover:after {
  opacity: .5;
}
.raport-property-list-item .raport-property-list-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.raport-property-list-item .raport-property-list-image:after {
  background-color: black;
  content: '';
  font-family: 'FontAwesome';
  display: table-cell;
  height: 100%;
  left: 0px;
  opacity: 0;
  color: #fff;
  position: absolute;
  text-align: center;
  top: 0px;
  line-height: 150px;
  transition: opacity .15s linear;
  vertical-align: middle;
  width: 100%;
  z-index: 3;
}
.raport-property-list-item .raport-property-list-image img {
  display: block;
  position: relative;
  vertical-align: middle;
}
.raport-property-list-item .raport-property-list-info {
  width: 80%;
  float: left;
  padding-left: 20px;
}
.report-module {
  margin-bottom: 50px;
}
.report-module-box {
  display: block;
  position: relative;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  padding: 15px;
  background: rgba(0, 0, 0, 0.02);
}
.landlord-back {
  display: block;
  font-size: 16px;
  margin: 20px 0;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.container .container {
  padding: 0 !important;
}
.background-image-cover {
  background-size: cover;
  background-position: center center;
}
.background-image-centered {
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.outsize-image {
  margin: -50px 0 -50px 0;
}
@media (max-width: 992px) {
  .outsize-image {
    margin: auto;
  }
}
.display-block {
  display: block;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pt50 {
  padding-top: 50px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.ptb50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.mt0 {
  margin-top: 0px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt-30 {
  margin-top: -30px !important;
}
.mt-60 {
  margin-top: -60px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mb0 {
  margin-bottom: 0px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb-30 {
  margin-bottom: -30px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.mb-60 {
  margin-bottom: -60px !important;
}
.no-margin {
  margin: 0px !important;
}
.no-padding {
  padding: 0px !important;
}
.strike {
  text-decoration: line-through;
}
.min-width {
  min-width: 1px;
}
.medium {
  font-weight: 500;
}
.semi-bold {
  font-weight: 600;
}
.nowrap {
  white-space: nowrap;
}
.blur {
  -webkit-filter: blur(3px);
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
h1.page-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.7);
  font-size: 28px;
  line-height: 60px;
  margin: 0px 0px 30px 0px;
  padding: 0px;
}
h2.page-header {
  font-size: 24px;
  line-height: 48px;
  padding: 0px;
}
h3.page-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  line-height: 40px;
  margin: 0px 0px 15px 0px;
  padding: 0px;
}
.small-title {
  color: #9E9E9E;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 0px 5px 0px;
  text-transform: uppercase;
}
.large-title {
  text-align: center;
}
.large-title h1 {
  color: #EC407A;
  font-size: 200px;
}
.large-title h2 {
  color: #9E9E9E;
  font-size: 36px;
}
p.text {
  color: #616161;
}
.block {
  margin: 0px 0px 30px 0px;
  padding: 60px 0px;
}
.block h1 {
  color: #616161;
  font-size: 30px;
  margin: 0px 0px 50px 0px;
  padding: 0px;
}
.block p {
  color: #616161;
  font-size: 14px;
  margin: 0px;
}
.block p a {
  border-bottom: 1px solid #EC407A;
  color: #EC407A;
}
.block p a:hover {
  text-decoration: none;
}
.box {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin: 0px 0px 30px 0px;
  padding: 16px;
}
.box h1 {
  margin: 10px 0px 26px 0px;
  font-size: 22px;
}
.fullwidth {
  margin-left: -750px;
  margin-right: -750px;
  padding-left: 750px;
  padding-right: 750px;
}
.background-grey {
  background-color: #F5F5F5;
}
.background-map {
  background-image: url("../img/map.png");
  background-position: center center;
  background-repeat: repeat;
}
.background-white {
  background-color: #fff;
}
.center {
  text-align: center;
}
.vertical-align {
  vertical-align: middle;
}
.min-width {
  width: 1px;
}
.alert {
  border-radius: 0px;
  border: 0px;
  border-left: 3px solid transparent;
}
.alert-info {
  background-color: #E1F5FE;
  border-left-color: #81D4FA;
  color: #0277BD;
}
.alert-success {
  background-color: #E8F5E9;
  border-left-color: #A5D6A7;
  color: #2E7D32;
}
.alert-danger {
  background-color: #FFEBEE;
  border-left-color: #EF9A9A;
  color: #C62828;
}
.alert-warning {
  background-color: #FFF8E1;
  border-left-color: #FFE082;
  color: #FF6F00;
}
.circle-color {
  border-radius: 50%;
  display: inline-block;
  height: 36px;
  margin: 0px 15px 15px 0px;
  width: 36px;
}
.circle-color-red {
  background-color: #EF5350;
}
.circle-color-pink {
  background-color: #EC407A;
}
.circle-color-deep-purple {
  background-color: #7E57C2;
}
.circle-color-indigo {
  background-color: #5C6BC0;
}
.circle-color-blue {
  background-color: #42A5F5;
}
.circle-color-light-blue {
  background-color: #29B6F6;
}
.circle-color-cyan {
  background-color: #26C6DA;
}
.circle-color-teal {
  background-color: #26A69A;
}
.circle-color-green {
  background-color: #66BB6A;
}
.circle-color-light-green {
  background-color: #9CCC65;
}
.circle-color-lime {
  background-color: #D4E157;
}
.circle-color-yellow {
  background-color: #FFEE58;
}
.circle-color-amber {
  background-color: #FFCA28;
}
.circle-color-orange {
  background-color: #FFA726;
}
.circle-color-deep-orange {
  background-color: #FF7043;
}
.circle-color-brown {
  background-color: #8D6E63;
}
.circle-color-blue-grey {
  background-color: #78909C;
}
.sharing-buttons {
  margin-top: 20px;
  padding-left: 0px;
}
.sharing-buttons li {
  display: inline-block;
  margin-bottom: 16px;
}
.sharing-buttons li a {
  background-color: #EC407A;
  color: #fff;
  font-size: 13px;
  padding: 8px 15px;
  position: relative;
  text-decoration: none;
}
.sharing-buttons li a:before {
  content: "\f1e0";
  font-family: 'FontAwesome';
  font-size: 17px;
  -webkit-font-smoothing: antialiased;
  left: 50%;
  margin-left: -8px;
  opacity: 0;
  position: absolute;
  transition: all linear .2s;
}
.sharing-buttons li a .fa-left {
  transition: all linear .2s;
  margin-right: 6px;
}
.sharing-buttons li a.facebook {
  background-color: #466ab3;
}
.sharing-buttons li a.twitter {
  background-color: #3bbceb;
}
.sharing-buttons li a.google-plus {
  background-color: #db6558;
}
.sharing-buttons li a .social-name {
  transition: all linear .2s;
}
.sharing-buttons li a:hover:before {
  opacity: 1;
}
.sharing-buttons li a:hover .fa,
.sharing-buttons li a:hover .social-name {
  opacity: 0;
}
.hero {
  margin: 0px 0px 60px 0px;
}
.hero h1,
.hero p {
  margin: 0px;
  text-align: center;
}
.hero h1 {
  color: #424242;
  font-size: 36px;
  font-weight: 500;
  margin: 0px 0px 20px 0px;
  text-transform: uppercase;
}
.hero p {
  font-size: 18px;
  line-height: 30px;
}
.position-relative {
  position: relative;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-offset: 0  !important;
  background-image: none  !important;
  -webkit-box-shadow: none !important;
  box-shadow: none  !important;
}
.warning-box {
  background: red;
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  color: #fff;
  text-align: center;
}
.col-md-1 .container,
.col-md-2 .container,
.col-md-3 .container,
.col-md-4 .container,
.col-md-5 .container,
.col-md-6 .container,
.col-md-7 .container,
.col-md-8 .container,
.col-md-9 .container,
.col-md-10 .container,
.col-md-11 .container {
  width: auto;
  padding: 0;
}
body {
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  background-color: #F1F6FA;
  line-height: 160%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  color: #666666;
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
h1 .action,
h2 .action,
h3 .action,
h4 .action,
h5 .action,
h6 .action {
  color: #E91E63;
  font-size: 12px;
  float: right;
  margin-top: 6px;
}
h1 .action:hover,
h2 .action:hover,
h3 .action:hover,
h4 .action:hover,
h5 .action:hover,
h6 .action:hover {
  text-decoration: none;
}
h1 {
  font-size: 28px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
h1 {
  position: relative;
  color: #000;
  font-weight: 500;
}
h2 {
  font-size: 24px;
  position: relative;
  margin: 15px 0;
  color: #000;
  font-weight: 300;
}
h3 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 300;
}
h4 {
  font-size: 18px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #AAAAAA;
}
h5 {
  font-size: 14px;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}
h6 {
  font-size: 12px;
  margin-bottom: 10px;
}
a {
  color: #000;
}
.container-title {
  text-align: center;
  margin-top: 5px;
  padding-right: 50px;
  padding-bottom: 5px;
}
h3.container-subtitle {
  margin-top: -30px;
  color: #757575;
  margin-bottom: 40px;
  font-size: 15px;
}
.blue-color {
  color: #E80000;
}
.blue-color a {
  color: #E80000;
}
blockquote {
  background-color: #fff;
  border: 1px solid #F5F5F5;
  color: #424242;
  font-style: italic;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  padding: 16px 16px 16px 80px;
  position: relative;
}
blockquote:before {
  color: #EC407A;
  content: '\f10e';
  display: block;
  font-family: 'FontAwesome';
  font-size: 45px;
  left: 16px;
  position: absolute;
  top: 25px;
}
ol {
  color: #616161;
  list-style-position: inside;
  padding: 0px;
}
ol li {
  margin-bottom: 10px;
}
ul {
  list-style-position: inside;
  padding: 0px;
}
ul li {
  margin-bottom: 10px;
}
#map-contact {
  height: 300px;
}
.page-wrapper {
  width: 100%;
}
.main {
  display: table-row;
}
.main a {
  transition: color .15s linear;
}
.custom-space {
  background-color: #E91E63;
  color: #fff;
  margin-bottom: 30px;
}
.custom-space-caption {
  background-color: #D81B60;
  font-size: 12px;
  padding: 2px 16px;
}
.custom-space-inner {
  margin: 0px 0px 30px 0px;
  padding: 16px;
}
.custom-space-inner h1 {
  margin: 0px 0px 10px 0px;
  font-size: 34px;
}
.custom-space-inner p {
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
}
.module {
  background-color: #fff;
  border: 1px solid #EEEEEE;
  margin-bottom: 30px;
  display: table;
  width: 100%;
}
.module-info {
  background-color: #FAFAFA;
  display: table-cell;
  font-size: 12px;
  padding: 16px;
  text-transform: uppercase;
}
.module-info .fa {
  color: #616161;
  font-size: 18px;
  vertical-align: middle;
}
.module-content {
  display: table-cell;
  padding: 16px;
}
.module-content strong {
  color: #EC407A;
  font-weight: 600;
  text-transform: uppercase;
}
.module-content span {
  font-weight: 600;
}
.logo-inverted {
  display: none;
}
.fixed-nav #header,
.fixed-nav #header.homepage {
  position: fixed;
  top: 0;
}
.fixed-nav #header .top-info,
.fixed-nav #header.homepage .top-info {
  display: none;
}
.fixed-nav #header .mobile-trigger i,
.fixed-nav #header.homepage .mobile-trigger i {
  color: #000;
}
.fixed-nav #header .header-title,
.fixed-nav #header.homepage .header-title {
  margin-top: 0;
}
.fixed-nav #header .header-title .logo img,
.fixed-nav #header.homepage .header-title .logo img {
  max-width: 200px;
  max-height: 40px;
}
.fixed-nav #header .site-main-nav ul li > a,
.fixed-nav #header.homepage .site-main-nav ul li > a {
  line-height: 20px;
  height: 20px;
}
.fixed-nav #header #mobilenav,
.fixed-nav #header.homepage #mobilenav {
  margin-top: 10px;
}
.fixed-nav #header .favorites-info,
.fixed-nav #header.homepage .favorites-info {
  margin-top: 5px;
}
.fixed-nav #header .logo-inverted,
.fixed-nav #header.homepage .logo-inverted {
  display: none;
}
.fixed-nav #header .logo-default,
.fixed-nav #header.homepage .logo-default {
  display: block;
}
#header.homepage {
  position: absolute;
  border-bottom: none;
}
#header.homepage .header-outter {
  background: rgba(232, 0, 0, 0.9);
}
.header {
  z-index: 2;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}
.menu-box {
  float: right;
  margin-right: 10px;
}
.favorites-info {
  display: none;
  float: left;
  margin-left: 20px;
  margin-top: 15px;
}
.favorites-info.active {
  display: block;
}
.favorites-info .favwrapper {
  position: relative;
  display: block;
  width: 25px;
  height: 30px;
}
.favorites-info #favcount {
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
  color: #000;
  font-size: 11px;
}
.favorites-info .fa {
  position: absolute;
  font-size: 25px;
  color: #fff;
}
.header {
  z-index: 10;
}
.header:after {
  content: "";
  display: table;
  clear: both;
}
.header .top-info,
.footer .top-info {
  padding: 15px 5px 15px 5px;
  border-bottom: 1px solid #f0f0f0;
  background: rgba(255, 255, 255, 0.8);
}
.header .info-box,
.footer .info-box {
  float: right;
}
.header .info-box-item,
.footer .info-box-item {
  display: inline-block;
  padding: 0;
  font-size: 13px;
  color: #77776A;
}
.header .info-box-item:after,
.footer .info-box-item:after {
  content: "•";
  display: inline-block;
  padding: 0 5px;
  color: #77776A;
}
.header .info-box-item i,
.footer .info-box-item i {
  margin-right: 3px;
}
.header .info-box-item:last-child:after,
.footer .info-box-item:last-child:after {
  content: '';
  display: none;
}
.header .info-box-item a,
.footer .info-box-item a {
  color: #77776A;
}
.header .social-box,
.footer .social-box {
  float: right;
  margin-right: 15px;
  color: #77776A;
}
.header .social-box a,
.footer .social-box a {
  color: #77776A;
  display: inline-block;
  font-size: 15px;
}
.header .social-box a:hover,
.footer .social-box a:hover {
  opacity: 1;
}
.header .social-box:after,
.footer .social-box:after {
  content: "";
  display: table;
  clear: both;
}
.header .header-info,
.footer .header-info {
  float: right;
  margin-top: 30px;
}
.header .header-info .info-box-item,
.footer .header-info .info-box-item {
  display: inline-block;
  padding: 0 25px;
  color: #77776A;
}
.header .header-info .info-box-item.with-border,
.footer .header-info .info-box-item.with-border {
  border-right: 1px solid #DDDDDD;
}
.header .header-info .info-box-item a,
.footer .header-info .info-box-item a {
  color: #77776A;
}
.header .header-info .info-box-item i,
.footer .header-info .info-box-item i {
  color: #E80000;
  font-size: 40px;
  float: left;
}
.header .header-info .info-box-item .info-box-content,
.footer .header-info .info-box-item .info-box-content {
  overflow: hidden;
  padding-left: 20px;
  font-size: 16px;
  color: #545454;
  font-weight: 300;
}
.header .header-info .info-box-item .info-box-content span,
.footer .header-info .info-box-item .info-box-content span {
  color: #000;
}
.site-main-nav-wrapper {
  background-color: #E80000;
  color: #fff;
}
.header {
  display: block;
  z-index: 100;
  width: 100%;
}
.header .logo img {
  max-width: 450px;
  max-height: 116px;
}
.logo_addon {
  width: 160px;
  display: block;
}
.header-outter {
  background: #E80000;
}
.header-inner {
  position: relative;
}
.header-minimal .header-inner {
  padding: 10px 0px;
}
.header-large .header-main {
  margin: 37px 0px;
}
.header-title {
  float: left;
  font-size: 30px;
  margin-top: -50px;
}
.header-addon {
  float: right;
  margin-top: 20px;
}
.header-minimal .header-title {
  font-size: 24px;
  margin-top: 8px;
}
.header-title img {
  margin-right: 15px;
  vertical-align: -8px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-title img {
    margin-right: 0px;
  }
}
.header-title a {
  color: #fff;
}
.header-minimal .header-title a {
  color: #424242;
}
.header-minimal.primary .header-title a {
  color: #fff;
}
.header-title a:hover {
  color: #fff;
  text-decoration: none;
}
.header-minimal .header-title a:hover {
  color: #424242;
}
.header-minimal.primary .header-title a:hover {
  color: #fff;
}
.header-title a:active,
.header-title a:focus {
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-title span {
    display: none;
  }
}
.header-title:hover img {
  opacity: 1;
}
.header-title-image {
  fill: #90CAF9;
  height: 36px;
  margin-right: 10px;
  vertical-align: -6px;
  width: 36px;
}
.header-search {
  float: right;
  margin-left: 100px;
  width: 550px;
}
@media (max-width: 767px) {
  .header-search {
    clear: both;
    float: none;
    margin: 0px;
    padding: 20px 0px 0px 0px;
    width: 100%;
  }
}
.header-search .input-group {
  margin: 0px;
}
.header-search .form-control {
  background-color: transparent;
  box-shadow: none;
  border: 0px;
  border-bottom: 2px solid #90CAF9;
  border-radius: 0px;
  color: #fff;
  font-size: 30px;
  height: 60px;
  padding: 0px;
  transition: border-color 0.15s linear;
}
.header-search .form-control::-webkit-input-placeholder {
  color: #fff;
}
.header-search .form-control:-moz-placeholder {
  color: #fff;
}
.header-search .form-control::-moz-placeholder {
  color: #fff;
}
.header-search .form-control:-ms-input-placeholder {
  color: #fff;
}
.header-search .form-control:focus {
  border-bottom-color: #fff;
}
.header-search .form-control:focus ~ .input-group-btn .btn,
.header-search .form-control:focus ~ .input-group-btn .btn-secondary {
  border-bottom-color: #fff;
}
.header-search .input-group-btn .btn,
.header-search .input-group-btn .btn-secondary {
  background-color: transparent;
  border: 0px;
  border-bottom: 2px solid #90CAF9;
  border-radius: 0px;
  box-shadow: none;
  height: 60px;
  transition: border-color 0.15s linear;
}
.header-search .input-group-btn .btn i,
.header-search .input-group-btn .btn-secondary i {
  font-size: 28px;
  vertical-align: middle;
}
.header-search .input-group-btn .btn:hover,
.header-search .input-group-btn .btn-secondary:hover,
.header-search .input-group-btn .btn:focus,
.header-search .input-group-btn .btn-secondary:focus {
  color: #fff;
  outline: 0px !important;
}
.header-search .input-group-btn .btn:after,
.header-search .input-group-btn .btn-secondary:after,
.header-search .input-group-btn .btn:before,
.header-search .input-group-btn .btn-secondary:before {
  display: none;
}
.header-top {
  margin: 20px 0px 30px 0px;
}
.header-top ul {
  float: right;
  margin: 0px;
  padding: 0px;
}
.header-top ul li {
  margin: 0px 0px 0px 30px;
}
.header-top ul li a {
  color: #64B5F6;
  font-weight: 500;
  padding: 0px;
  transition: color .15s linear;
}
.header-top ul li a:hover {
  background-color: transparent;
  color: #BBDEFB;
}
.header-top ul li a:active,
.header-top ul li a:focus {
  background-color: transparent;
}
.header-top .social {
  margin-left: 30px;
  margin-top: 2px;
}
.header-top .social li {
  margin: 0px 8px;
}
.header-top .social li i {
  font-size: 16px;
  margin: 0px 2px;
}
.header-top .social li:last-child {
  margin-right: 0px;
}
.header-topbar {
  background-color: #000;
  color: #77776A;
  padding: 7px 0px 7px 0px;
  min-height: 36px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 11px;
  font-weight: bold;
}
.header-topbar-left {
  float: left;
}
@media (max-width: 767px) {
  .header-topbar-left {
    float: none;
  }
}
.header-topbar-right {
  float: right;
}
@media (max-width: 767px) {
  .header-topbar-right {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    float: none;
    margin-top: 8px;
    padding-top: 8px;
  }
}
.header-topbar-social {
  display: inline;
  margin: 0px;
  padding: 0px;
}
.header-topbar-social li {
  display: inline;
  list-style: none;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}
.header-topbar-social li a {
  color: #BBDEFB;
  font-size: 15px;
  vertical-align: middle;
}
.header-topbar-social li a:hover {
  color: #E3F2FD;
}
.header-topbar-links {
  display: inline;
}
.header-topbar-links li {
  display: inline;
  padding-left: 10px;
  vertical-align: middle;
}
.header-topbar-links li:first-child {
  padding-left: 0px;
}
.header-topbar-links li img {
  margin: -2px 5px 0px 0px;
  vertical-align: middle;
}
.header-topbar-links li a {
  color: #E3F2FD;
}
.header-topbar-links li a:hover {
  text-decoration: none;
}
@media (max-width: 768px) {
  .header-topbar {
    display: none;
  }
  .logo_addon {
    display: none;
  }
  .header-title {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .header-title .logo {
    display: inline-block;
  }
  .header-title .logo img {
    max-width: 200px !important;
  }
}
@media (max-width: 1200px) {
  .top-info {
    display: none;
  }
  .favorites-info {
    position: absolute;
    top: 20px;
    right: 20px;
    margin-top: 0;
  }
  .header-topbar {
    display: none;
  }
  #nav-wrapper {
    display: none;
  }
  .header-title {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .header-title .logo {
    display: inline-block;
  }
  .header-title .logo img {
    max-width: 400px;
    max-height: 50px;
  }
}
@media (max-width: 992px) {
  .header-title {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .header-title .logo {
    display: inline-block;
  }
  .header-title .logo img {
    max-width: 400px;
    max-height: 50px;
  }
  .header-addon {
    display: none;
  }
  .logo_addon {
    display: none;
    margin: 0;
  }
}
.top-phone {
  position: fixed;
  background: #E80000;
  padding: 10px 20px;
  font-size: 22px;
  right: 0;
  top: 0;
}
.top-phone.info-box-item {
  font-size: 16px;
  font-weight: bold;
}
.top-phone.info-box-item .fa {
  font-size: 22px;
}
.before-footer {
  display: block;
  height: 50px;
}
.footer-logo {
  margin-bottom: 10px;
}
.scrolltotop {
  position: absolute;
  top: -30px;
  right: 10px;
  display: block;
  padding: 5px 15px;
  background: #E80000;
}
.scrolltotop:hover {
  opacity: .8;
}
.scrolltotop i {
  font-size: 24px;
}
.footer {
  background-color: #E80000;
  display: table;
  height: 1px;
  width: 100%;
  color: #fff;
  position: relative;
}
.footer .container {
  position: relative;
}
.footer .footer-top {
  padding: 30px 0 20px 0;
  background: url('../images/footer-bg.png') repeat-x center bottom;
}
.footer h2,
.footer h3,
.footer h4 {
  color: #fff;
  font-size: 18px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.28;
  padding-bottom: 5px;
  position: relative;
  margin: 0 0 10px;
}
.footer a {
  color: #fff;
}
.footer-bottom {
  padding: 25px 0 15px 0;
  background: #7EA0AA;
  font-size: 13px;
  color: #fff;
}
.footer-bottom a {
  color: #fff;
  font-weight: bold;
}
.footer-bottom-left {
  float: left;
}
@media (max-width: 767px) {
  .footer-bottom-left {
    float: none;
  }
}
.footer-bottom-right {
  float: right;
}
@media (max-width: 767px) {
  .footer-bottom-right {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    float: none;
    margin-top: 30px;
    padding-top: 30px;
  }
  .footer-simple .footer-bottom-right {
    border-top-color: #F5F5F5;
  }
}
.footer-bar {
  background-color: #1976D2;
  color: #fff;
  padding-bottom: 7px;
  padding-top: 7px;
}
.footer-bar .scroll-top {
  color: #fff;
  cursor: pointer;
  font-size: 40px;
}
.footer input {
  background: none;
}
.footer .newsletter-module input[type=submit].btn-block {
  margin: 0;
  background: #E80000;
  border: none;
}
.footer .newsletter-module input[type=submit].btn-block:hover {
  background: #9c0000;
}
.mm-menu ul li > a {
  color: #000 !important;
  padding-right: 18px !important;
}
.site-main-nav {
  bottom: 0;
  position: relative;
  float: right;
}
.site-main-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.site-main-nav ul:before {
  content: " ";
  display: table;
}
.site-main-nav ul li {
  position: relative;
  float: left;
  padding: 0;
  margin: 0;
}
.site-main-nav ul li:last-of-type > a {
  padding-right: 0;
}
.site-main-nav ul li:hover > a {
  color: #fff;
  border-bottom: 3px solid #fff;
}
.site-main-nav ul li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.site-main-nav ul li > a {
  display: block;
  padding: 0 17px;
  height: 65px;
  line-height: 70px;
  background: none;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.site-main-nav ul li > a.active {
  color: #fff;
  border-bottom: 3px solid #fff;
}
.site-main-nav ul li > a.with-sub:after {
  content: ' \f107';
  font-family: "FontAwesome";
}
.site-main-nav ul li .sub-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 130%;
  left: 0;
  z-index: 1000;
  padding: 12px;
  background: #E80000;
  width: 240px;
  transition: all 0.25s;
}
.site-main-nav ul li .sub-menu a {
  text-transform: none;
  border: none;
}
.site-main-nav ul li .sub-menu:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 10px;
  top: -10px;
  opacity: 0;
}
.site-main-nav ul li .sub-menu li {
  float: none;
}
.site-main-nav ul li .sub-menu a {
  float: none;
  font-size: 14px;
  padding: 5px 15px;
  color: #fff;
  height: auto;
  line-height: normal;
}
.site-main-nav ul li .sub-menu a:hover {
  color: #fff;
}
.read-more {
  display: inline-block;
  padding: 5px 20px;
  color: #333;
  border: 1px solid #333;
}
.read-more.rm-negative {
  color: #fff;
  border: 1px solid #fff;
}
.read-more:hover {
  color: #E80000 !important;
  border-color: #E80000;
  text-decoration: none;
}
.mobile-trigger i {
  font-size: 18px;
  color: #fff;
}
.homepage-menu .mobile-trigger i {
  color: #fff;
}
#mm-mobilenav .mm-listview .sub-menu {
  display: block;
  position: relative;
  background: none;
  width: auto;
  visibility: visible;
  opacity: 1;
  padding: 0;
}
#mm-mobilenav .mm-listview li {
  float: none !important;
}
#mm-mobilenav .mm-listview li a {
  width: 100%;
  float: none;
  text-align: center;
}
#mobile-header {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 100;
}
ul.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.menu:before {
  content: " ";
  display: table;
}
ul.menu li {
  position: relative;
  float: left;
  padding: 0 10px;
  border-right: 1px solid #fff;
}
ul.menu li:last-of-type {
  border-right: none;
  padding-right: 0;
}
.footer-menu a {
  color: #fff;
}
.mm-page {
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
@media (min-width: 1200px) {
  #mobile-header {
    display: none;
  }
}
@media (max-width: 1200px) {
  .site-main-nav {
    display: none;
  }
}
.pagination {
  float: right;
}
.pagination .active span {
  background-color: #E80000;
  border-color: #E80000;
}
.btn-red {
  background: #E80000;
  color: #fff;
  padding: 15px 25px;
}
.btn-red:hover {
  background: rgba(232, 0, 0, 0.8);
  color: #fff;
}
.btn-login {
  margin: -25px 0;
}
.property-disclaimer {
  margin-top: 30px;
  font-size: 13px;
  color: #abb2cc;
}
.property-sharing {
  margin: 0 auto;
}
.property-box-list-agent {
  margin-top: 10px;
}
.property-box-small {
  clear: both;
  padding-bottom: 20px;
}
.property-box-small .property-box-small-title {
  font-size: 14px;
  margin-bottom: 5px;
}
.property-box-small .property-box-small-image {
  float: left;
  margin: 0 20px 20px 0;
}
.property-box-small .property-box-small-image img {
  position: relative;
  width: 60px;
}
.property-box-small .property-box-price {
  text-overflow: initial;
  float: none;
  width: auto;
  overflow: visible;
  font-size: 16px;
  padding: 0;
}
.at-share-btn-elements {
  text-align: center;
}
.property-amenities {
  margin: 0px 0px 30px 0px;
}
.property-amenities ul {
  list-style: none;
  margin-bottom: 0px;
  padding: 0px;
}
.property-amenities li {
  color: #424242;
  line-height: 34px;
}
.property-amenities li.yes:before,
.property-amenities li.no:before {
  color: #BDBDBD;
  content: '\f00d';
  font-family: 'FontAwesome';
  font-size: 12px;
  margin: 0px 10px 0px 0px;
  vertical-align: top;
}
.property-amenities li.yes:before {
  color: #EC407A;
}
.property-badge {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  color: #757575;
  display: block;
  font-size: 11px;
  font-weight: 600;
  left: 16px;
  line-height: 1;
  padding: 7px 12px;
  position: absolute;
  text-transform: uppercase;
  top: 16px;
}
.property-box {
  background-color: #fff;
  margin: 0px 0px 30px 0px;
  position: relative;
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  overflow: hidden;
}
.property-box:hover {
  -webkit-box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
}
.property-box h2 {
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  margin: 5px 0 0 0;
  width: 100%;
  line-height: 150%;
  text-align: left;
}
.property-box h2 a {
  color: #424242;
  text-decoration: none;
}
.property-box .property-box-location {
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  color: #777;
  float: left;
}
.property-box .property-box-location i {
  margin-right: 5px;
  color: #E80000;
}
.property-box .property-box-title-overlay .property-box-location {
  color: #fff;
}
.property-box-header {
  padding: 5px 15px 5px;
  height: 55px;
  overflow: hidden;
}
.property-box-title-overlay-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: all 0.6s ease 0s;
  padding: 20px;
}
.property-box-title-overlay-hover h2 {
  color: #fff;
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
.property-box-title-overlay-hover .property-box-meta-citem {
  color: #fff;
}
.property-box-title-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  display: block;
  padding: 10px 15px 5px 15px;
  margin: 0;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ff000000', GradientType=0);
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}
.property-box-title-overlay .property-box-price {
  float: right;
  width: auto;
  padding: 0;
  color: #fff;
  font-size: 18px;
}
.property-box-title-overlay .property-box-location {
  float: left;
  width: auto;
}
.property-box-title-overlay .property-box-meta {
  display: none;
  clear: both;
  color: #fff;
  font-size: 12px;
}
.property-box-title-overlay .property-box-meta .property-box-meta-citem {
  color: #fff;
}
.property-box-title-overlay h2 {
  color: #fff;
  display: none;
}
.property-box-image {
  overflow: hidden;
}
.property-box-image img {
  transition: all 0.7s ease;
}
.property-box:hover .property-box-image img {
  transform: scale(1.1);
}
.property-box-line {
  height: 1px;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  border-top: 1px solid #EEEEEE;
}
.property-box-content {
  padding: 0 15px 60px 15px;
  margin-top: 0;
}
.property-box-image {
  background-color: rgba(0, 0, 0, 0.12);
  min-height: 150px;
  position: relative;
  opacity: 1;
  transition: opacity .5s;
}
.property-box-agent {
  margin-bottom: 5px;
  position: absolute;
  bottom: 0;
}
.property-box-agent-image {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.property-box-agent-image img {
  width: 35px !important;
  height: 35px !important;
  border-radius: 100%;
}
.property-box-agent-info {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-weight: normal;
}
.property-box-agent-info .property-box-agent-name {
  font-size: 12px;
  font-weight: 500;
  display: block;
  line-height: 12px;
  color: #1F1F1F;
}
.property-box-agent-info .property-box-agent-role {
  font-size: 12px;
}
.property-box-meta {
  height: 30px;
  overflow: hidden;
  margin-top: 15px;
  display: table;
  width: 100%;
  position: relative;
}
.property-box-meta-citem {
  display: table-cell;
  color: #515666;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 10px;
  font-weight: 500;
}
.property-box-meta-citem:last-child {
  border-right: none;
}
.property-box-meta-citem i {
  color: #BBBBBB;
  margin-right: 2px;
  display: inline-block;
  width: 20px;
  line-height: 20px;
  font-size: 14px;
}
.property-box-meta-citem i:before {
  font-size: 14px;
}
.property-box-meta-citem .fa {
  opacity: .6;
}
.property-box-action-post {
  float: right;
  width: 25%;
}
.property-box-action-post .property-box-action-item {
  display: inline-block;
  line-height: 55px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 53px;
  float: right;
  border-right: 0;
  border-left: 1px solid #e5e5f8;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  color: #abb2cc;
}
.property-box-action-post .property-box-action-item:hover {
  background-color: #f9a11b;
  color: #fff;
}
.property-box-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 50px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.footer .property-box-image:before {
  color: #42A5F5;
}
.property-box-image img {
  position: relative;
  width: 100%;
}
.property-box-image:hover .property-box-excerpt {
  opacity: 1;
}
.property-box-meta-item {
  float: left;
  text-align: center;
  width: 33.33%;
}
.property-box-meta-item span,
.property-box-meta-item strong {
  display: block;
}
.property-box-meta-item span {
  color: #9E9E9E;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: .85;
}
.property-box-meta-item strong {
  color: #424242;
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
  opacity: .85;
}
.property-box-bottom {
  background: #fbfbfe;
  border-top: 1px solid #e5e5f8;
  border-bottom: 4px solid #e5e5f8;
  overflow: hidden;
}
.property-box-price {
  color: #E80000;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: right;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  line-height: 32px;
}
.property-box-more {
  position: absolute;
  bottom: 10px;
  right: 15px;
  display: block;
  line-height: 32px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: #E80000;
  text-align: center;
  border-radius: 3px;
}
.property-box-more:hover {
  text-decoration: none;
  color: darkern(#E80000, 10%);
}
.property-box-view {
  color: #212121;
  float: right;
  font-size: 14px;
  text-transform: uppercase;
}
.property-box-view:hover {
  color: #212121;
  text-decoration: none;
}
.property-small {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 15px 0px;
}
.footer .property-small {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.property-small:last-child {
  border: 0px;
  margin: 0px;
  padding: 0px;
}
.property-small-inner {
  display: table;
  width: 100%;
}
.property-small-image {
  display: table-cell;
  width: 96px;
}
.property-small-image img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2;
}
.property-small-image-inner {
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  height: 60px;
  margin: 0px 16px 0px 0px;
  position: relative;
  width: 80px;
}
.property-small-image-inner:after {
  background-color: black;
  background-image: url("../img/magnifier.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  content: '';
  display: table-cell;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0px;
  transition: opacity .15s linear;
  vertical-align: middle;
  width: 100%;
  z-index: 3;
}
.property-small-image-inner:hover:after {
  opacity: .5;
}
.property-small-image-inner:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.footer .property-small-image-inner:before {
  color: #42A5F5;
}
.property-small-image-inner:after {
  background-size: 22px 22px;
}
.property-small-content {
  display: table-cell;
  vertical-align: top;
}
.property-small-title {
  margin: 0px;
}
.property-small-title a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: 500;
}
.footer .property-small-title a {
  color: #fff;
}
.property-small-location {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.property-small-location li {
  display: inline;
}
.property-small-location li a {
  color: #9E9E9E;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}
.footer .property-small-location li a {
  color: rgba(255, 255, 255, 0.6);
}
.footer .property-small-location li {
  color: rgba(255, 255, 255, 0.6);
}
.property-small-price {
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
}
.property-simple-wrapper {
  margin-bottom: -30px;
}
.property-simple-image {
  background-color: rgba(0, 0, 0, 0.15);
  display: block;
  min-height: 75px;
  position: relative;
}
.property-simple-image:after {
  background-color: black;
  background-image: url("../img/magnifier.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  content: '';
  display: table-cell;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0px;
  transition: opacity .15s linear;
  vertical-align: middle;
  width: 100%;
  z-index: 3;
}
.property-simple-image:hover:after {
  opacity: .5;
}
.property-simple-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.footer .property-simple-image:before {
  color: #42A5F5;
}
.property-simple-image img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2;
}
.property-simple-title {
  font-size: 15px;
  margin: 15px 0px 0px 0px;
}
.property-simple-title a {
  color: #232323;
}
.property-simple-location {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.property-simple-location li {
  display: inline;
}
.property-simple-location li a {
  color: #9E9E9E;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}
.property-simple-status {
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  margin: 3px 0px 0px 0px;
}
.property-simple-status strong.red {
  color: #EF5350;
}
.property-simple-status strong.green {
  color: #66BB6A;
}
.property-gallery-list-wrapper {
  padding: 30px 60px 14px 60px;
}
.property-gallery-list-item {
  float: left;
  list-style: none;
  padding: 0px 2px;
  position: relative;
}
.property-gallery-list-item a {
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  height: 50px;
  width: 50px;
}
.property-gallery-list-item a img {
  display: block;
  height: 50px;
  position: relative;
  width: 50px;
  z-index: 2;
}
.property-gallery-list-item a:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 20px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.footer .property-gallery-list-item a:before {
  color: #42A5F5;
}
.property-list {
  margin: 0px 0px 30px 0px;
}
.property-list dl {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}
.property-list dt {
  color: #616161;
  float: left;
  font-weight: normal;
  line-height: 36px;
}
.property-list dd {
  border-bottom: 1px solid #EEEEEE;
  color: #424242;
  font-weight: 600;
  line-height: 36px;
  text-align: right;
}
.property-list dd:last-child {
  border-bottom: 0px;
}
.property-carousel-wrapper {
  background-color: #fff;
  border: 1px solid #F5F5F5;
  margin: 0px 0px 30px 0px;
  padding: 16px 50px;
}
@media (max-width: 767px) {
  .property-carousel {
    width: 250px;
  }
  .property-carousel .property-simple-image {
    min-height: 60px;
  }
}
.property-carousel .owl-item {
  overflow: hidden;
  width: 0px;
}
.property-carousel .owl-prev,
.property-carousel .owl-next {
  color: #EC407A;
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.property-carousel .owl-prev {
  left: -30px;
}
.property-carousel .owl-next {
  right: -30px;
}
.property-carousel-item {
  padding: 0px 10px;
}
.property-carousel-item > div {
  margin-bottom: 0px;
}
.properties-sort {
  background-color: #fff;
  border: 1px solid #F5F5F5;
  margin: 0px 0px 30px 0px;
  padding: 16px;
  display: block;
}
.not-found {
  margin: 120px 0px 0px 0px;
}
.not-found-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 60px 0px 40px 0px;
  padding: 0px 0px 40px 0px;
}
.not-found-title strong {
  color: #BDBDBD;
  font-size: 150px;
  margin-right: 20px;
}
.not-found-title span {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.not-found-content h4 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 16px;
  font-weight: 500;
  margin: 0px 0px 20px 0px;
  padding: 0px 0px 15px 0px;
}
.not-found-subtitle {
  margin: 0px 0px 40px 0px;
  padding: 0px;
}
.not-found-list {
  font-size: 16px;
}
.not-found-list a {
  border-bottom: 1px solid #EC407A;
}
.not-found-list a:hover {
  border-bottom: 1px solid #D81B60;
}
.not-found-list li {
  line-height: 36px;
}
.not-found-links {
  margin: 0px;
}
.not-found-links li {
  list-style: none;
}
.not-found-links a {
  color: #424242;
}
.not-found-links a:hover {
  text-decoration: underline;
}
.property-box-meta:before,
.property-box-bottom:before,
.property-box-meta:after,
.property-box-bottom:after {
  display: table;
  content: " ";
}
.property-box-meta:after,
.property-box-bottom:after {
  clear: both;
}
.property-box-title {
  margin-top: 5px;
  height: 42px;
  overflow: hidden;
}
.label-wrapper {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 10;
}
.label-wrapper.label-right {
  right: 15px;
  left: auto;
}
.label {
  display: inline-block;
  margin-right: 5px;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px 5px;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 11px;
  vertical-align: top;
  font-weight: 500;
  z-index: 3;
  border-radius: 30px;
  margin-bottom: 5px;
}
.label-transaction {
  background-color: #11C43A;
}
.label-top {
  background-color: #fab909;
}
.label-status2,
.label-status3,
.label-status4 {
  background-color: #E80000;
}
.label-featured {
  background-color: #7EA0AA;
}
.label-commision {
  background-color: #E80000;
}
.properties-sort-info {
  float: left;
}
.properties-sort-form {
  float: right;
}
.property-box .label,
.property-box-list .label {
  display: block;
}
@media (max-width: 767px) {
  .property-box h2 {
    overflow: visible;
    white-space: normal;
    font-size: 16px;
  }
}
.propertyspecial {
  margin: 0;
}
.propertyspecial .col-property {
  padding: 0;
}
.propertyspecial .col-property .property-box {
  padding: 0;
  margin-bottom: 0;
}
.favorite-icon {
  color: #fff;
  z-index: 100;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.favorite-icon.active .on {
  display: inline-block;
  color: red;
}
.favorite-icon.active .off {
  display: none;
}
.favorite-icon .on {
  display: none;
}
.favorite-icon i {
  font-size: 30px;
}
.viewwrapper {
  margin-right: 15px;
}
.viewwrapper .btn-default {
  background: none;
}
.viewwrapper .btn-default.active {
  background: #EDF1F2;
}
.col-property-list {
  position: relative;
  margin-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-property-list:after {
  content: ' ';
  clear: both;
}
.property-box-list {
  background: #fff;
  border: 1px solid #EEEEEE;
  position: relative;
}
.property-box-list:hover {
  -webkit-box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
}
.property-box-list-content {
  margin-top: 15px;
}
.more-details {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  background: #E80000;
  color: #fff;
  padding: 5px 15px;
  text-align: center;
  font-size: 13px;
}
.more-details:hover {
  color: #fff;
  text-decoration: none;
  background: #b50000;
}
.property-box-list-contentwrapper {
  padding: 5px 10px 10px 0;
  position: relative;
}
.property-box-list-contentwrapper h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #424242;
  margin: 5px 0;
}
.property-box-list-contentwrapper h2 a {
  color: #424242;
}
.property-box-list-contentwrapper h2 a:hover {
  text-decoration: none;
}
.property-box-list-contentwrapper .property-box-list-meta {
  margin-bottom: 10px;
}
.property-box-list-contentwrapper .property-box-list-meta-citem {
  display: inline-block;
  color: #515666;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #EDF1F2;
  padding: 5px 10px;
}
.property-box-list-contentwrapper .property-box-list-meta-citem i {
  color: #BBBBBB;
  margin-right: 2px;
  display: inline-block;
  width: 20px;
  line-height: 20px;
  font-size: 14px;
}
.property-box-list-contentwrapper .property-box-list-meta-citem i:before {
  font-size: 14px;
}
.property-box-list-contentwrapper .property-box-list-meta-citem .fa {
  opacity: .6;
}
.property-box-list-contentwrapper .property-box-list-more {
  display: block;
  float: right;
  background: #7EA0AA;
  color: #fff;
  padding: 5px 25px;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 3px;
}
.property-box-list-contentwrapper .property-box-list-more:hover {
  background: #618894;
  text-decoration: none;
}
.property-box-list-contentwrapper .property-box-list-price {
  color: #E80000;
  font-weight: bold;
  font-size: 22px;
  float: right;
}
.property-box-list-image {
  position: relative;
}
.property-box-list-image img {
  width: 100%;
}
.property-box-list-description {
  font-size: 14px;
  height: 75px;
  overflow: hidden;
  margin-top: 10px;
  opacity: .7;
}
.property-content {
  margin-top: 5px;
  position: relative;
}
.property-content .favorite-icon i {
  font-size: 25px;
  margin-top: 0;
}
.property-cover {
  position: relative;
  min-height: 300px;
  overflow: hidden;
  margin-bottom: 30px;
}
.property-cover .property-cover-image {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background-size: cover;
  background-position: center center;
  -webkit-filter: blur(15px);
  /* Safari 6.0 - 9.0 */
  filter: blur(15px);
}
.property-cover-info {
  color: #fff;
  position: relative;
  margin-top: 50px;
}
.property-cover-info h1,
.property-cover-info h2,
.property-cover-info h3,
.property-cover-info h4,
.property-cover-info a {
  color: #fff;
}
.property-cover-info .property-show-zone {
  padding: 6px 15px;
  border: 1px dashed #D9D9D9;
}
.property-delimiter-box {
  margin-bottom: 30px;
}
.divider-header-property {
  padding: 10px 0 !important;
}
.property-wrapper .property-content h2,
.property-wrapper .property-content h3,
.property-wrapper .property-content h4,
.property-wrapper .property-content h5 {
  text-align: left;
}
.property-wrapper .property-content h2.page-header {
  text-align: left;
  color: #111111;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  margin: 0 0 25px;
}
.property-wrapper .property-content h2 {
  color: #000;
  font-size: 18px;
}
.property-wrapper .property-content h2:after {
  display: none;
}
.property-wrapper .property-content h2 .first-word {
  color: #3a3f4c;
}
.property-aside-content .property-delimiter-box {
  background: #fff;
}
.property-content-gallery {
  margin: -30px -30px 0 -30px;
}
.property-main-gallery .property-main-gallery-item {
  text-align: center;
}
.property-main-gallery .property-main-gallery-item img {
  max-width: 100%;
  height: auto;
  max-height: 540px;
  display: block;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
}
.property-show-price {
  font-weight: 300;
  display: block;
  text-align: right;
  font-size: 20px;
  color: #E80000;
  float: right;
}
.property-show-short-caracteristics {
  margin-top: 30px;
  text-align: center;
  float: left;
  margin-right: 20px;
}
.property-show-short-caracteristics .box-short i,
.property-show-short-caracteristics .box-short i:before {
  font-size: 40px;
}
.property-show-short-caracteristics .box-short .box-short-title {
  font-weight: bold;
  display: block;
}
.property-show-lista-tabelara {
  margin: 0;
  padding: 0;
}
.property-show-lista-tabelara li {
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #f7f7f7;
}
.property-show-lista-tabelara li span {
  float: right;
  font-weight: bold;
}
.property-show-specs ul {
  list-style: none;
}
.property-show-specs ul .fa {
  opacity: .5;
}
.property-show-specs ul li span {
  font-weight: bold;
}
#window-map {
  height: 350px;
}
.property-show-contact-short .agent-small-content {
  border-bottom: none;
}
.agent-contact-phone {
  clear: both;
  background: #E80000;
  padding: 15px;
  color: #fff;
  font-size: 18px;
  display: block;
  text-align: center;
  margin-top: 15px;
}
.contact-form {
  margin-top: 30px;
}
.contact-form textarea {
  min-height: 120px;
}
.contact-form .btn {
  width: 100%;
  color: #fff;
  background: #E80000;
  padding: 15px 30px;
  border-radius: 5px;
}
.property-show-contact-vertical {
  border: 1px solid #EEEEEE;
  padding: 30px;
}
.btn-print {
  width: 100%;
}
.property-show-title {
  font-size: 30px;
  margin: 5px 0 30px 0;
  padding: 0;
  font-weight: normal;
}
.property-show-zone {
  margin-top: 0;
  font-size: 16px;
  color: #666666;
  float: left;
}
.property-show-id {
  clear: both;
}
.property-show-visits {
  padding-left: 20px;
}
.property-main-gallery {
  margin-top: 10px;
}
.property-main-gallery-item img.img-portrait {
  width: auto !important;
}
.property-content .label-wrapper {
  top: -20px;
}
.property-show-specs h5 {
  font-size: 16px;
  color: #111;
}
.property-aside-content .property-delimiter-box {
  border: 1px solid #f0f0f0;
  padding: 0 15px 15px 15px;
}
.property-show-box {
  background: #fff;
  padding: 15px;
}
.property-show-box,
.property-show-description {
  margin-bottom: 30px;
}
.property-short-caracteristics {
  margin: 30px 0;
  display: table;
  width: 100%;
}
.property-short-caracteristics .property-box-shortc {
  display: table-cell;
  vertical-align: middle;
}
.property-short-caracteristics .property-box-shortc span {
  padding-right: 10px;
}
.property-short-caracteristics .property-box-shortc b {
  font-size: 24px;
}
@media (max-width: 767px) {
  .property-short-caracteristics .property-box-shortc span {
    display: block;
  }
}
.favicon-property-show {
  float: right;
  margin-right: 10px;
}
.favicon-property-show .favorite-icon {
  color: #666;
}
.bx-wrapper .bx-next:after {
  font-family: "FontAwesome";
  content: "\f054";
  text-indent: 0 !important;
  font-size: 20px;
  color: #fff;
}
.bx-wrapper {
  margin-bottom: 0;
}
#property-main-gallery-pager {
  text-align: center;
  margin-top: 15px;
}
#property-main-gallery-pager a img {
  max-width: 100px;
}
#property-main-gallery-pager a.active {
  opacity: .5;
}
.bx-wrapper .bx-pager {
  bottom: 10px !important;
}
/*
[ 29. Agents ]
*/
.agents-wrapper {
  margin: 20px 0 0 0;
}
.agents-wrapper .agent-show-box {
  background: #fff;
  padding-top: 15px;
  border: 1px solid #eee;
}
.agents-wrapper .agent-info-box {
  float: left;
  color: #fff !important;
}
.agents-wrapper .agent-info-box:hover {
  z-index: 3;
  box-shadow: 0 70px 70px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.agents-wrapper .agent-info-box .agent-info-box-image {
  margin: 0 auto;
  text-align: center;
  display: block;
}
.agents-wrapper .agent-info-box .agent-info-box-image img {
  max-width: 180px;
  margin: 0 auto;
  border: 2px solid #fff;
}
.agent-small {
  display: none;
}
.agent-info-box {
  width: 100%;
  max-width: 270px;
  position: relative;
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
  padding: 20px 5px 10px 5px;
  background: #fff;
}
.agent-info-box .agent-info-box-image img {
  width: 100%;
}
.agent-info-box .agent-info-box-content {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.agent-info-box .agent-info-box-content .agent-info-box-content-inner {
  padding: 10px;
}
.agent-info-box .agent-info-box-content .agent-info-box-social {
  margin-top: 5px;
  padding: 10px;
}
.agent-info-box .agent-info-box-content .agent-info-phone {
  font-size: 18px;
  color: #777777;
}
.agent-info-box .agent-info-box-content .agent-info-email {
  font-size: 13px;
}
.agent-info-box .agent-info-box-content h2 {
  color: #E80000 !important;
  font-size: 16px;
  margin: 5px 0 15px 0;
  font-weight: 500;
}
.agent-info-box .agent-info-box-content h2:after {
  display: none;
}
.agent-info-box .agent-info-box-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.agent-info-box .agent-info-box-content ul li {
  margin: 0;
  padding: 0;
}
.white-agent .agent-info-box .agent-info-box-content-inner,
.white-agent .agent-info-box .agent-info-box-social {
  background: #fff;
}
.agent-card {
  margin-bottom: -30px;
}
.agent-card h2 {
  margin-top: 0px;
}
.agent-card-info {
  color: #616161;
}
.agent-card-info ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.agent-card-info ul li {
  margin: 0px;
}
.agent-card-info ul .fa {
  margin-right: 5px;
}
.agent-card-image {
  background-color: rgba(0, 0, 0, 0.15);
  display: block;
  height: 0px;
  padding-bottom: 100%;
  position: relative;
}
.agent-card-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 50px;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%), translateY(-50%);
}
.footer .agent-card-image:before {
  color: #42A5F5;
}
.agent-card-image img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2;
}
.agent-card-form .form-group:last-child {
  margin-bottom: 0px;
}
.agent-small {
  margin: 0px 0px 15px 0px;
}
.agent-small:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.agent-small:last-child .agent-small-content {
  border-bottom: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.agent-small-inner {
  display: table;
  width: 100%;
}
.agent-small-image {
  display: table-cell;
  width: 76px;
}
.agent-small-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2;
}
.agent-small-image-inner {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  height: 50px;
  float: left;
  margin: 0px 16px 0px 0px;
  position: relative;
  width: 50px;
}
.agent-small-image-inner:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 18px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.footer .agent-small-image-inner:before {
  color: #42A5F5;
}
.agent-small-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: table-cell;
  padding-bottom: 15px;
  vertical-align: top;
}
.footer .agent-small-content {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.agent-small-title {
  margin: 0px 0px 10px 0px;
}
.agent-small-title a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: 500;
}
.footer .agent-small-title a {
  color: #fff;
}
.agent-small-email,
.agent-small-phone {
  font-size: 12px;
  line-height: 22px;
}
.agent-small-email .fa,
.agent-small-phone .fa {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  margin: 0px 6px 0px 0px;
  vertical-align: middle;
}
.footer .agent-small-email .fa,
.footer .agent-small-phone .fa {
  color: rgba(255, 255, 255, 0.7);
}
.agent-small-email a:hover,
.agent-small-phone a:hover {
  text-decoration: none;
}
.footer .agent-small-email a,
.footer .agent-small-phone a {
  color: #fff;
}
.agent-simple {
  margin-bottom: 30px;
}
.agent-simple-title {
  color: #616161;
  font-size: 20px;
}
.agent-simple-title a {
  color: #616161;
}
.agent-simple-title a:hover {
  text-decoration: none;
}
.agent-simple-image {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  height: 0px;
  margin: 0px 0px 30px 0px;
  padding-bottom: 100%;
  position: relative;
}
.agent-simple-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 55px;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%), translateY(-50%);
}
.footer .agent-simple-image:before {
  color: #42A5F5;
}
.agent-simple-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2;
}
.agent-simple-content {
  bottom: 45px;
  left: 0px;
  position: absolute;
  z-index: 3;
}
.agent-simple-content span {
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  line-height: 2;
  padding: 0px 8px;
}
.agent-simple-content span .fa {
  margin-right: 5px;
}
.agent-simple-content .agent-simple-email {
  margin: -3px 0px;
}
.agent-row {
  background-color: #F5F5F5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .agent-row {
    background-color: #fff;
  }
}
.agent-row .row,
.agent-row .property-amenities ul,
.property-amenities .agent-row ul {
  display: table;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}
.agent-row .row > div,
.agent-row .property-amenities ul > div,
.property-amenities .agent-row ul > div {
  display: table-cell;
  float: none;
  vertical-align: top;
}
@media (max-width: 767px) {
  .agent-row .row > div,
  .agent-row .property-amenities ul > div,
  .property-amenities .agent-row ul > div {
    display: block;
  }
}
.agent-row-image {
  background-color: #fff;
  padding: 16px;
}
.agent-row-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2;
}
.agent-row-image-inner a {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  height: 0px;
  padding-bottom: 100%;
  position: relative;
}
.agent-row-image-inner a:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 50px;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%), translateY(-50%);
}
.footer .agent-row-image-inner a:before {
  color: #42A5F5;
}
.agent-row-content {
  background-color: #fff;
}
.agent-row-content p {
  color: #616161;
}
.agent-row-title a {
  color: #424242;
}
.agent-row-title a:hover {
  text-decoration: none;
}
.agent-row-subtitle {
  color: #616161;
  font-size: 15px;
}
.agent-row-info {
  padding: 16px;
}
.agent-row-info ul {
  color: #424242;
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.agent-row-info ul .fa {
  color: #616161;
  margin: 0px 5px 0px 0px;
}
.agent-row-info ul li {
  margin: 0px;
}
.agent-medium {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  padding: 16px;
}
.agent-medium-image {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  float: left;
  height: 120px;
  position: relative;
  width: 120px;
}
.agent-medium-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.footer .agent-medium-image:before {
  color: #42A5F5;
}
.agent-medium-image img {
  border-radius: 50%;
  position: relative;
  width: 100%;
  z-index: 2;
}
.agent-medium-content {
  color: #616161;
  margin: 0px 0px 0px 140px;
}
.agent-medium-content ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.agent-medium-content ul .fa {
  margin: 0px 5px 0px 0px;
}
.agent-medium-content ul li {
  margin: 0px;
}
.agent-medium-content hr {
  margin: 10px 0px;
}
.agent-medium-title {
  margin: 0px;
}
.agent-medium-title a {
  color: #424242;
}
.agent-medium-title a:hover {
  text-decoration: none;
}
.agent-medium-subtitle {
  color: #616161;
  font-size: 13px;
}
.agents-wrapper.agentslist .agent-info-box {
  float: none;
  width: 100%;
  max-width: none;
}
.agents-wrapper.agentslist .agent-info-box .agent-info-box-content-inner,
.agents-wrapper.agentslist .agent-info-box .agent-info-box-social {
  text-align: left;
}
.agent-show-box-title {
  text-align: left;
}
.agent-show-box-content ul,
.agent-show-box-content li {
  list-style: none;
}
.agent-show-box-content .agent-show-box-description {
  margin-top: 20px;
}
.agent-info-box-social .fa,
.agent-show-box-social .fa {
  font-size: 18px;
  display: inline-block;
  border: 1px solid #BABABA;
  color: #BABABA;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.agent-info-box-social .fa:hover,
.agent-show-box-social .fa:hover {
  background: #E80000;
  color: #fff;
  border: 1px solid #E80000;
}
.agent-show-latestproperties {
  margin-top: 50px;
}
.search-form-vertical {
  background: #EDF1F2;
  padding: 0 15px 15px 15px;
  border: 1px solid #f0f0f0;
  margin-top: 20px;
}
.search-form-vertical .btn-primary {
  background: #E80000;
}
.search-form-wrapper .search-inner {
  position: relative;
}
.search-wrapper {
  padding-top: 30px;
}
#mm-mobilenav .logo-mobile {
  max-width: 250px;
  text-align: center;
  margin: 0 auto;
}
#mm-mobilenav .logo-mobile img {
  margin: 0 auto;
}
#mm-mobilenav .mm-listview .sub-menu {
  display: block;
  position: relative;
  background: none;
  width: auto;
}
#mm-mobilenav .mm-listview li {
  float: none !important;
}
#mm-mobilenav .mm-listview li a {
  width: 100%;
  float: none;
  text-align: center;
}
#mobile-header {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 100;
}
.logo-mobile img {
  max-height: 100px;
}
.divider-box {
  padding: 50px 0 50px;
}
.divider-simple {
  padding: 50px 0 50px;
}
.divider-grey {
  padding: 50px 0px 50px 0px;
  background-color: #f5f5f5;
  margin: 0;
}
.divider-white {
  padding: 50px 0px 50px 0px;
  background-color: #fff;
}
.divider-grey0 {
  background: #EDF1F2;
  padding: 0;
}
.divider-image1 {
  background-image: url('../images/background-blue.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.divider-image1 h1,
.divider-image1 h2,
.divider-image1 h3,
.divider-image1 p,
.divider-image1 a {
  color: #fff;
}
.divider-darkgrey {
  background: #6E7C85;
}
.divider-footerblue {
  padding: 30px 0 30px;
  background-color: #E80000;
  color: #fff;
}
.divider-footerblue h1,
.divider-footerblue h2,
.divider-footerblue h3,
.divider-footerblue h4,
.divider-footerblue a {
  color: #fff;
}
.divider-testimonials {
  padding: 20px 0;
  background-size: cover;
  margin: 0;
}
.divider-testimonials h1,
.divider-testimonials h2,
.divider-testimonials h3,
.divider-testimonials p {
  color: #fff;
}
.divider-black {
  padding: 20px 0;
  background: #1C1E1E;
}
.divider-black h1,
.divider-black h2,
.divider-black h3,
.divider-black a {
  color: #fff;
}
.divider-firma {
  padding: 0;
}
.divider-firma .row,
.divider-firma .col-sm-6 {
  padding: 0;
  margin: 0;
}
.firma-wrapper {
  padding: 20px;
  text-align: right;
  max-width: 600px;
  float: right;
}
.firma-wrapper h1,
.firma-wrapper h2,
.firma-wrapper h3 {
  text-align: right;
}
.firma-wrapper .firma-link {
  font-size: 16px;
  display: inline-block;
  margin-left: 30px;
}
.divider-inpagesearch {
  padding: 10px 0 5px 0;
  text-align: center;
  background: #f5f5f5;
}
.complexes-rows-wrapper {
  margin-top: 30px;
}
.complex-row {
  position: relative;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}
.complex-row:after {
  content: '';
  background: #f9a11b;
  height: 4px;
  width: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.complex-row:hover {
  -webkit-box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
}
.complex-row:hover:after {
  width: 100%;
  opacity: 1;
}
.complex-row:before {
  display: table;
  content: " ";
}
.complex-row .property-row-image {
  width: 30%;
  height: auto;
  min-height: 160px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.15);
  display: block;
  float: left;
}
.complex-row .property-row-image img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2;
}
.complex-row .property-row-content {
  width: 70%;
  margin: 0;
  padding: 0 20px;
  float: left;
  min-height: 195px;
  position: relative;
}
.complex-box-content {
  background: #E80000;
  color: #fff;
  text-align: center;
}
.complex-box-content h1,
.complex-box-content h2,
.complex-box-content h3,
.complex-box-content h4,
.complex-box-content h5,
.complex-box-content p,
.complex-box-content a {
  color: #fff;
  padding: 0;
  margin: 0;
}
.complex-box-content .complex-box-title {
  height: 25px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .complex-row .property-row-image {
    width: 100%;
    max-width: 400px;
  }
  .complex-row .property-row-content {
    width: 100%;
  }
}
.complexes-map-canvas {
  width: 100%;
  height: 350px;
}
.infowindow-wrapper img {
  max-width: 150px !important;
}
.infowindow-wrapper .infowindow-title {
  display: block;
  padding: 5px;
  text-align: center;
  font-weight: bold;
}
.property-infowindow-image {
  float: left;
  margin-right: 10px;
}
.property-infowindow-content {
  float: right;
  max-width: 200px;
}
.bx-slider .bx-wiewport {
  background: none;
}
.bx-wrapper {
  background: none;
}
.testimonial-slider-item {
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.testimonial-slider-item .testimonial-slider-item-content {
  font-size: 16px;
  font-weight: 300;
  display: block;
  padding: 30px;
  background: #F3F3F3;
  position: relative;
}
.testimonial-slider-item .testimonial-slider-item-content .fa {
  display: none;
}
.testimonial-slider-item .testimonial-slider-item-content:after {
  content: "";
  position: absolute;
  top: 40px;
  left: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 30px solid #F3F3F3;
}
.testimonial-slider-item .testimonial-slider-item-image {
  display: block;
  max-width: 90px;
  border-radius: 50%;
  overflow: hidden;
  min-height: 50px;
  margin: 0 auto;
}
.testimonial-slider-item .testimonial-slider-item-author {
  display: block;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.testimonials-wrapper {
  width: 100%;
  margin-top: 20px;
}
.testimonial-item {
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
}
.testimonial-item .testimonial-item-content {
  font-size: 16px;
  font-weight: 300;
  display: block;
  overflow: hidden;
}
.testimonial-item .testimonial-item-image {
  display: block;
  float: right;
  margin-right: 15px;
  max-width: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -20px;
}
.testimonial-item .testimonial-item-author {
  display: block;
  float: right;
  font-size: 24px;
  font-weight: 300;
}
.container .half-splash {
  margin: 0;
}
.half-splash {
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 40px 0;
}
.half-splash .container {
  padding: 0;
}
.half-splash .half-splash-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
}
.half-splash .half-splash-overlay {
  opacity: .8;
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.half-splash .half-splash-overlay.half-splash-mode-right {
  left: 50%;
}
.half-splash .half-splash-overlay.half-splash-mode-full {
  width: 100%;
}
.half-splash .half-splash-overlay.half-splash-color-white {
  background: #fff;
}
.half-splash .half-splash-overlay.half-splash-color-blue {
  background: #1C547B;
  opacity: .4;
}
.half-splash .half-splash-overlay.half-splash-color-green {
  background: #49cf47;
}
.half-splash .half-splash-overlay.half-splash-color-orange {
  background: #FF8000;
}
.half-splash .half-splash-overlay.half-splash-color-black {
  background: #000;
  opacity: .4;
}
.half-splash .half-splash-overlay.half-splash-color-grey {
  background: #757584;
  opacity: .6;
}
.half-splash .half-splash-overlay.half-splash-color-gradient {
  background: -webkit-linear-gradient(top, rgba(241, 246, 250, 0.2) 50%, #f1f6fa 95%);
  background: -o-linear-gradient(top, rgba(241, 246, 250, 0.2) 50%, #f1f6fa 95%);
  background: linear-gradient(to bottom, rgba(241, 246, 250, 0.2) 50%, #f1f6fa 95%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33f1f6fa', endColorstr='#fff1f6fa', GradientType=0);
  opacity: 1;
}
.half-splash .slider-gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40%;
}
.half-splash .half-splash-content p,
.half-splash .half-splash-content h1,
.half-splash .half-splash-content h2,
.half-splash .half-splash-content h3,
.half-splash .half-splash-content h4 {
  color: #fff;
}
.half-splash label {
  color: #fff;
}
.halfsplash-home {
  text-align: center;
  min-height: 500px;
  padding-top: 250px;
}
.shortsearchform .form-group {
  margin-right: 3px;
}
.shortsearchform .form-group:first-child .input-blue.form-control {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.shortsearchform .selectize-dropdown-content {
  text-align: left;
}
.shortsearchform .form-group {
  vertical-align: top;
}
.shortsearchform .selectize-input,
.shortsearchform .selectize-control.multi .selectize-input.has-items {
  padding: 12px;
  height: 50px;
  border-radius: 0;
  border: none;
  min-width: 400px;
  text-align: left;
}
.shortsearchform .selectize-input input,
.shortsearchform .selectize-control.multi .selectize-input.has-items input {
  line-height: 25px !important;
}
.shortsearchform .search-inner {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: top;
}
.shortsearchform .aditional-search {
  height: 50px;
  display: inline-block;
  vertical-align: top;
}
.shortsearchform .aditional-search .btn {
  margin: 0 !important;
  background: #E80000;
  font-size: 14px;
  height: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
::placeholder {
  color: #999;
}
.divider-inpagesearch {
  padding: 0;
}
.divider-inpagesearch .search-form-wrapper {
  padding: 15px 15px 0 15px;
  background: #7EA0AA;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.divider-inpagesearch .btn {
  border-bottom: none !important;
}
.divider-inpagesearch .search-form-wrapper .search-inner input.form-control {
  height: 35px;
  padding: 5px 10px;
}
.divider-inpagesearch .search-form-wrapper .search-inner .btn {
  height: 35px;
  padding: 5px 10px;
}
.divider-inpagesearch .search-form-wrapper .btn {
  background: #E80000;
  margin-bottom: 15px !important;
}
.half-splash .search-form-wrapper {
  padding: 0 30px 30px 30px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}
.half-splash .search-form-wrapper .search-inner {
  border: none;
}
.half-splash .search-form-wrapper .search-inner input.btn-primary {
  width: auto;
  margin: 0 auto;
  font-size: 14px;
}
.half-splash .full-group {
  width: 100%;
  position: relative;
}
.half-splash .full-group .form-control {
  width: 100%;
}
.half-splash .full-group .selectize-input {
  min-height: 40px;
  font-size: 16px;
  padding: 12px 6px 12px 6px;
  height: 64px;
  line-height: 40px;
}
.half-splash .search-icon {
  position: absolute;
  top: 6px;
  left: 10px;
  z-index: 1000;
}
.half-splash .search-icon i {
  color: #E80000;
  font-size: 26px;
}
.search-form-wrapper .btn-primary {
  background: #E80000;
  border: none;
}
.search-form-wrapper .btn-primary:active {
  background: #b50000;
}
.search-form-vertical .btn-primary {
  background: #E80000;
  border: none;
}
.search-form-vertical .btn-primary:active {
  background: #b50000;
}
@media (max-width: 768px) {
  .half-splash .search-form-wrapper {
    margin: 15px auto 15px;
    width: 100%;
    padding: 20px;
  }
  .half-splash .short-location {
    width: 70%;
    float: left;
  }
}
.search-inner .input-short {
  height: 50px;
  border-radius: 0;
  margin-right: 3px;
}
.search-inner .input-short button {
  line-height: 25px;
  border-radius: 0;
}
.search-inner .input-short button .filter-option {
  padding-right: 10px;
}
.search-inner .input-red.form-control {
  background: #E80000;
}
.search-inner .input-red.open .dropdown-toggle.btn-default:hover {
  background: none !important;
  border: none !important;
  color: #fff;
}
.search-inner .input-red .btn-default {
  background: none !important;
  border: none !important;
  box-shadow: none;
  color: #fff;
}
.search-inner .input-red .btn-default:hover {
  background: none !important;
}
.search-inner .input-blue.form-control {
  background: #7EA0AA;
}
.search-inner .input-blue.open .dropdown-toggle.btn-default:hover {
  background: none !important;
  border: none !important;
  color: #fff;
}
.search-inner .input-blue .btn-default {
  background: none !important;
  border: none !important;
  box-shadow: none;
  color: #fff;
}
.search-inner .input-blue .btn-default:hover {
  background: none !important;
}
.search-inner .input-transparent.form-control {
  background: none;
}
.search-inner .input-transparent.open .dropdown-toggle.btn-default:hover {
  background: none !important;
  border: none !important;
  color: #fff;
}
.search-inner .input-transparent .btn-default {
  background: none !important;
  border: none !important;
  box-shadow: none;
  color: #fff;
}
.search-inner .input-transparent .btn-default:hover {
  background: none !important;
}
@media (max-width: 768px) {
  .shortsearchform .selectize-input,
  .shortsearchform .selectize-control.multi .selectize-input.has-items {
    min-width: 0;
  }
  .shortsearchform .aditional-search {
    float: right;
    width: 28%;
  }
}
.map-canvas {
  min-height: 350px;
}
#transportation,
#supermarkets,
#schools,
#libraries,
#pharmacies,
#hospitals {
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  float: left;
  clear: left;
  position: relative;
  white-space: nowrap;
  height: 40px;
  margin-bottom: 1px;
}
#transportation .icon,
#supermarkets .icon,
#schools .icon,
#libraries .icon,
#pharmacies .icon,
#hospitals .icon {
  width: 40px;
  height: 40px;
  padding: 4px;
  position: relative;
}
#transportation img,
#supermarkets img,
#schools img,
#libraries img,
#pharmacies img,
#hospitals img {
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
#transportation.active span,
#supermarkets.active span,
#schools.active span,
#libraries.active span,
#pharmacies.active span,
#hospitals.active span {
  z-index: 1;
  -moz-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}
#transportation span,
#supermarkets span,
#schools span,
#libraries span,
#pharmacies span,
#hospitals span {
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  -moz-transform: translate3d(48%, 0, 0);
  -o-transform: translate3d(48%, 0, 0);
  -ms-transform: translate3d(48%, 0, 0);
  -webkit-transform: translate3d(48%, 0, 0);
  transform: translate3d(48%, 0, 0);
  padding-right: 12px;
  padding-left: 16px;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
  visibility: hidden;
}
a.text-icon-link {
  text-decoration: none !important;
}
a.text-icon-link:hover .text-icon {
  background: #81be32;
}
.text-icon {
  display: block;
  position: relative;
  overflow: visible;
  margin-top: 20px;
  transition: all 0.5s ease;
}
.text-icon .text-icon-icon {
  text-align: center;
  display: block;
  position: relative;
  height: 80px;
  width: 80px;
  margin: 0 auto;
  opacity: .8;
  background: #E80000;
  transform: rotate(45deg);
}
.text-icon .text-icon-icon .flaticon:before,
.text-icon .text-icon-icon .flaticon:after {
  font-size: 70px;
}
.text-icon .text-icon-icon i {
  transform: rotate(-45deg);
  font-size: 40px !important;
  text-align: center;
  line-height: 80px;
  color: #fff;
}
.text-icon .text-icon-icon [class^="flaticon-"]:before,
.text-icon .text-icon-icon [class*=" flaticon-"]:before,
.text-icon .text-icon-icon [class^="flaticon-"]:after,
.text-icon .text-icon-icon [class*=" flaticon-"]:after {
  font-size: 45px;
}
.text-icon h3 {
  margin: 15px 0 10px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}
.text-icon .text-icon-body {
  min-height: 50px;
  text-align: center;
  margin-top: 30px;
}
.text-icon .text-icon-body p {
  font-size: 14px;
  opacity: .8;
}
.divider-third .text-icon h3 {
  color: #fff;
}
#google_translate_element {
  display: inline-block;
}
#google_translate_element a {
  color: #333;
}
/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */
.flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('../images/flags.png') no-repeat;
}
.flag.flag-de {
  background-position: 0 0;
}
.flag.flag-hu {
  background-position: 0 -24px;
}
.flag.flag-fr {
  background-position: -24px 0;
}
.flag.flag-gb {
  background-position: -48px 0;
}
.flag.flag-ro {
  background-position: -24px -24px;
}
.homepage-slider {
  padding: 0;
}
.homepage-slider .property-slider-wrapper {
  height: 500px;
}
.homepage-slider .property-slider-item {
  min-height: 500px;
}
.homepage .owl-carousel {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .homepage-slider {
    margin-top: 0;
  }
}
.properties-slider-wrapper {
  height: 570px;
  overflow: hidden;
}
.property-slider-item {
  min-height: 570px;
  position: relative;
  color: #000;
}
.property-slider-item .property-slider-item-location {
  display: block;
  font-size: 13px;
  color: #696969;
  text-transform: inherit;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
}
.property-slider-item .property-slider-item-location i {
  color: #E80000;
}
.property-slider-item .property-slider-item-meta-citem {
  padding-right: 10px;
}
.property-slider-item .property-slider-item-meta-citem i:before {
  font-size: 22px;
  color: #696969;
}
.property-slider-item .property-slider-info-wrapper {
  margin-top: 130px;
  float: right;
  display: block;
  z-index: 1;
  max-width: 450px;
}
.property-slider-item .property-slider-info {
  padding: 25px;
  background: #fff;
}
.property-slider-item .property-slider-info .property-slider-leftinfo {
  max-width: 360px;
}
.property-slider-item .property-slider-info .label-wrapper {
  position: relative;
  margin-bottom: 20px;
  display: block;
  left: 0;
}
.property-slider-item .property-slider-info .property-box-list-meta {
  margin-top: 15px;
}
.property-slider-item .property-slider-info .property-slider-more {
  background: #E80000;
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
}
.property-slider-item .property-slider-info .property-slider-more:hover {
  background: rgba(232, 0, 0, 0.8);
  text-decoration: none;
}
.property-slider-item .property-slider-info .property-slider-more span {
  display: inline-block;
  padding: 0 10px;
}
.property-slider-item .property-slider-info h3 {
  color: #000;
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 300;
}
.property-slider-item .property-slider-info h3 a {
  color: #424242;
  text-decoration: none;
}
.property-slider-item .property-slider-info .property-box-meta-citem {
  color: #fff;
}
.property-slider-item .property-slider-info .property-box-meta-citem i {
  color: #fff;
}
.property-slider-item .property-slider-info .property-box-meta-citem i:before {
  font-size: 22px;
}
.property-slider-item .property-slider-info .property-slider-price {
  font-size: 16px;
  font-weight: 600;
  color: #E80000;
  margin-top: 20px;
}
.property-slider-item .property-slider-info .property-slider-view {
  color: #E80000;
  float: right;
}
.property-slider-item .property-slider-meta {
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .properties-slider-wrapper,
  .property-slider-item {
    min-height: 650px;
  }
}
@media (max-width: 992px) {
  .properties-slider-wrapper,
  .property-slider-item {
    min-height: 550px;
  }
}
@media (max-width: 768px) {
  .properties-slider-wrapper,
  .property-slider-item {
    min-height: 350px;
  }
}
.menu-module {
  list-style: none;
}
.menu-module li {
  margin-bottom: 0;
}
.menu-module .columns-2 {
  width: 50%;
  padding-right: 10px;
  float: left;
}
.menu-module .columns-3 {
  width: 33%;
  padding-right: 10px;
  float: left;
}
.news-box-item {
  margin-bottom: 25px;
}
.news-box-item a {
  text-align: center;
}
.news-box-item h1 a,
.news-box-item h2 a,
.news-box-item h3 a {
  color: #000;
}
.news-box-item p {
  font-size: 14px;
  line-height: 140%;
}
.article-image {
  margin-bottom: 20px;
}
.article-content {
  font-size: 15px;
}
.article-date {
  font-size: 16px;
  color: #666;
  display: block;
  margin-bottom: 10px;
}
.article-wrapper {
  margin-bottom: 30px;
}
.article-wrapper .addthis_inline_share_toolbox {
  margin-top: 30px;
}
.news-box h3,
.news-box p {
  text-align: center;
}
.news-box a img {
  text-align: center;
  margin: 0 auto;
}
.header-band {
  padding: 15px 0 15px;
  position: relative;
}
.header-band h1 {
  font-size: 36;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
.header-band .header-band-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
}
.btn-wrapper-align-center {
  text-align: center;
}
.module-btn {
  color: #fff;
  display: inline-block;
  padding: 15px 30px;
  text-align: center;
  margin: 15px 0;
  font-size: 18px;
  border-radius: 5px;
}
.module-btn:hover {
  color: #fff;
  text-decoration: none;
}
.module-btn-color-red {
  background: #E80000;
}
.module-btn-color-red:hover {
  background: #cf0000;
  color: #fff;
  text-decoration: none;
}
.module-btn-color-orange {
  background: orange;
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;
}
.module-btn-color-orange:hover {
  background: rgba(255, 165, 0, 0.95);
  color: #fff;
  text-decoration: none;
}
.module-btn-color-blue {
  background: #62a8ea;
}
.module-btn-color-blue:hover {
  background: rgba(98, 168, 234, 0.95);
}
.invitebanner {
  background: #fff;
  padding: 30px 25px 30px 15px;
  border-radius: 5px;
}
.invitebanner .invite-title {
  font-size: 24px;
  font-weight: bold;
}
.invitebanner .btn {
  font-size: 13px;
  letter-spacing: 0.2px;
  color: white;
  border-radius: 5px;
  background: #E80000;
  text-transform: uppercase;
}
.invitebanner .btn:hover {
  color: white;
  text-decoration: none;
  background: rgba(232, 0, 0, 0.8);
}
html.with-featherlight {
  overflow: hidden;
}
.featherlight {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483647;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background: #333;
  background: rgba(0, 0, 0, 0);
}
.featherlight:last-of-type {
  background: rgba(0, 0, 0, 0.8);
}
.featherlight:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.featherlight .featherlight-content {
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: auto;
  padding: 25px 25px 0;
  border-bottom: 25px solid transparent;
  margin-left: 5%;
  margin-right: 5%;
  max-height: 95%;
  background: #fff;
  cursor: auto;
  white-space: normal;
}
.featherlight .featherlight-inner {
  display: block;
}
.featherlight script.featherlight-inner,
.featherlight link.featherlight-inner,
.featherlight style.featherlight-inner {
  display: none;
}
.featherlight .featherlight-close-icon {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  line-height: 25px;
  width: 25px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  background: #fff;
  background: rgba(255, 255, 255, 0.3);
  color: #000;
  border: none;
  padding: 0;
}
.featherlight .featherlight-close-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.featherlight .featherlight-image {
  width: 100%;
}
.featherlight-iframe .featherlight-content {
  border-bottom: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}
.featherlight iframe {
  border: none;
}
.featherlight * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .featherlight .featherlight-content {
    margin-left: 0;
    margin-right: 0;
    max-height: 98%;
    padding: 10px 10px 0;
    border-bottom: 10px solid transparent;
  }
}
@media print {
  html.with-featherlight > * > :not(.featherlight) {
    display: none;
  }
}
.properties-categories-short {
  color: #fff;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.properties-categories-short:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.5);
}
.properties-categories-short:hover i {
  color: #fff;
}
.properties-categories-short i {
  display: block;
  width: 100px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}
.properties-categories-short i:before {
  font-size: 45px;
}
@media (max-width: 768px) {
  .half-splash-content .properties-categories-short-wrapper {
    display: none;
  }
}
.halfsplash-home {
  text-align: center;
  padding-top: 300px;
  padding-bottom: 100px;
}
.halfsplash-home h1 {
  font-size: 40px;
  color: #000 !important;
  background: rgba(255, 255, 255, 0.5);
  display: inline-block;
  padding: 20px 30px;
  min-width: 665px;
  margin-bottom: 10px;
  font-weight: 300;
}
.ppt-white-box {
  background: #fff;
  padding: 40px;
  margin-top: 20px;
}
.ppt-white-box h1,
.ppt-white-box h2,
.ppt-white-box h3 {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.white-box {
  background: #fff;
  padding: 20px;
}
.page-container .sortable-container {
  background: #fff;
  padding: 20px;
}
.row.latest-properties.propertysmall {
  margin: 0;
}
.footer-searchshort {
  padding: 8px 0 8px;
}
.footer-searchshort .search-form-wrapper {
  margin-bottom: -20px;
}
.btn-success,
.btn-primary {
  background-color: #E80000;
  border-color: #E80000;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  margin: 15px 0;
}
.btn-success:hover,
.btn-primary:hover,
.btn-success:focus,
.btn-primary:focus,
.btn-success:active:hover,
.btn-primary:active:hover,
.btn-success:active:focus,
.btn-primary:active:focus,
.btn-success:active,
.btn-primary:active {
  background-color: rgba(232, 0, 0, 0.9);
  border-color: #E80000;
}
.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-prev:hover,
.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-next:hover {
  color: #000;
  background: none;
}
.owl-theme .owl-nav .owl-prev:before,
.owl-theme .owl-nav .owl-prev:hover:before,
.owl-theme .owl-nav .owl-next:before,
.owl-theme .owl-nav .owl-next:hover:before {
  font-size: 60px;
}
.homepage .latest-properties.owl-theme .owl-nav .owl-prev {
  left: -80px;
}
.homepage .latest-properties.owl-theme .owl-nav .owl-next {
  right: -80px;
}
.half-splash .newsletter-module {
  max-width: 400px;
  margin: 0 auto;
}
.newsletter-module input[type=submit].btn-block {
  width: auto;
  margin: 0 auto;
}
.redline-delim {
  margin: 20px auto;
}
.redline-delim div {
  width: 250px;
  height: 1px;
  background-color: #E80000;
  margin: 0 auto;
}
.news-box,
.article-wrapper {
  margin-top: 30px;
}
.home-sellproperty {
  margin-top: 80px;
  color: #fff;
}
.home-sellproperty h2 {
  margin-bottom: 0;
  padding: 5px;
}
.home-sellproperty a {
  color: #fff;
}
.home-sellproperty a i:before {
  font-size: 60px;
}
.home-sellproperty a:hover {
  opacity: .8;
  text-decoration: none;
}
.contact-social-item {
  margin-right: 10px;
}
@media (max-width: 992px) {
  .halfsplash-home {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .halfsplash-home h1 {
    min-width: 0;
    font-size: 18px;
    display: block;
  }
}
.form-control {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #d9e0e4;
}
.has-error .help-block {
  font-size: 13px;
  color: orangered;
}
.alert-danger {
  background: red;
  color: #fff;
}
.alert-danger .close {
  color: #fff;
  opacity: .8;
}
.has-error .form-control {
  border: 1px solid red;
}
.topquicksearch input {
  width: 100px;
  padding: 2px 5px;
  height: 30px;
}
.ofertaform,
.requestform {
  margin-bottom: 50px;
}
label.has-error {
  color: red !important;
}
.search-form-wrapper .search-inner .btn-primary {
  padding: 12px 30px;
  margin: 0;
  font-size: 14px;
}
.search-form-wrapper .search-inner input.form-control {
  padding: 12px 15px;
  height: 45px;
}
.search-form-wrapper .search-inner button.btn {
  padding: 12px 15px;
}
.breadcrumb {
  margin-bottom: 0;
}
.breadcrumb li {
  margin-bottom: 0;
}
.breadcrumb li a {
  font-size: 13px;
  opacity: .6;
}
.bootstrap-select .btn-default {
  background-color: #fff !important;
}
.bootstrap-select a:focus {
  outline: none;
}
.bootstrap-select .dropdown-menu > li {
  margin-bottom: 0;
}
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  background-color: #fff !important;
}
